.form-group.pzsfiles-container {
  margin-left: 1rem;
}

.form-group.pzs-upload h5 {
  font-size: 1rem;
}

.ajaxfileuploader .form-group {
  margin-bottom: 0;
}
.ajaxfileuploader .real.form-group {
  position: relative;
  top: -33px;
  opacity: 0.01;
  height: 32px;
  overflow: hidden;
  cursor: pointer;
}
.ajaxfileuploader .fake.form-control {
  position: relative;
}
.ajaxfileuploader .fake.form-control label {
  padding-left: 0;
  padding-top: 0;
}
.ajaxfileuploader .fake.form-control .action {
  position: absolute;
  top: 3px;
  right: 10px;
  line-height: 25px;
  height: 25px;
}
.ajaxfileuploader .real.form-group .form-control {
  cursor: pointer;
}
.ajaxfileuploader .btn.float-right {
  float: right;
}
.ajaxfileuploader table {
  margin-bottom: 2rem;
  border-bottom: 1px solid #dee2e6;
}
.ajaxfileuploader table.table-sm th, .ajaxfileuploader table.table-sm td {
  padding: 0.2em;
}
.ajaxfileuploader table .delete {
  cursor: pointer;
}
.ajaxfileuploader table .filename {
  width: 100%;
}
.ajaxfileuploader table .filename.filenameCustomByDocumentType {
  width: 40%;
  max-width: 40%;
  overflow-wrap: anywhere;
}
.ajaxfileuploader table .filetypeColumn {
  width: 46%;
  max-width: 46%;
}
.ajaxfileuploader table .filesize,
.ajaxfileuploader table .ext {
  white-space: nowrap;
  padding-right: 12px !important;
}
.ajaxfileuploader .ajaxfileuploader-uploaded,
.ajaxfileuploader .ajaxfileuploader-progress {
  display: none;
}
.ajaxfileuploader .ajaxfileuploader-uploaded .filename {
  cursor: pointer;
}
.ajaxfileuploader .ajaxfileuploader-uploaded .filename:hover {
  text-decoration: underline;
}
.ajaxfileuploader.afu-loading {
  cursor: wait !important;
}
.ajaxfileuploader .afu-outer {
  position: relative;
}
.ajaxfileuploader .spinner {
  color: #34547E;
  position: absolute;
  top: 0;
  right: 6px;
  opacity: 0;
  transition: opacity 1s ease-in-out 0.1s;
}
.ajaxfileuploader.afu-uploading, .ajaxfileuploader.afu-loading {
  cursor: wait !important;
}
.ajaxfileuploader.afu-uploading .spinner, .ajaxfileuploader.afu-loading .spinner {
  opacity: 1;
  transition: opacity 1s ease-in-out 0.1s;
}
.ajaxfileuploader.afu-uploading {
  cursor: wait !important;
}
.ajaxfileuploader.afu-uploading .ajaxfileuploader-uploaded,
.ajaxfileuploader.afu-uploading .ajaxfileuploader-progress {
  display: block;
}
.ajaxfileuploader.is-loaded .ajaxfileuploader-uploaded {
  display: block !important;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: text-bottom;
  border: 0.2em solid #34547E;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 2s linear infinite;
  transition: opacity 1s ease-in-out 0.1s;
}